@import "../../../UI/sass/colors";

.column {
    width: 100%;
    height: fit-content;
    background-color: $basic_1;
}

.priceWrapper {
    position: relative;
    flex-basis: 80px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.priceText {
    position: absolute;
    right: -50px;
    top: 50%;
    transform: translate(0, -50%);
}

.featureTitle {
    align-self: center;
}


.featureContainer {
    max-height: 450px;
    width: 60%;
    min-width: 660px;
    @media screen and (min-height: 1100px) {
        max-height: 800px;
    }
    @media screen and (min-height: 1400px) {
        max-height: 100%;
        min-width: fit-content;
        width: fit-content;
    }
}

.taxDisclaimer {
    color: rgba($basic_0, $a_medium);
}

.toHome {
    color: rgba($basic_0, $a_hard-soft);
}

