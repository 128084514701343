@import "../sass/colors";
@import "../sass/spacingUtils";

$width: 650px;

.container {
    position: absolute;
    bottom: 10px;
    left: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    max-width: $width + spacing(6) + spacing(6) + spacing(2);
    padding: spacing(2);
}

.toast {
    background-color: rgba($basic_0, $a_hard);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: $width;
    padding: spacing(4) spacing(6);
    border-radius: 4px;

    box-shadow: 0 4px 6px 0 rgba($basic_0, $a_light-soft);
}
