@import "../../sass/colors";

.mainContainer {
    position: relative;
    z-index: 9;
    width: 100%;
    &:hover {
        cursor: pointer;
    }
}

.list {
    background-color: $basic_1;
    border-radius: 5px;
    width: 250px;
    /* Position the tooltip */
    position: absolute;
    z-index: 11;
}

.buttonPlaceholder {
    max-width: calc(100% - 20px);
}
