@import '../../sass/colors';
@import '../../sass/spacingUtils';

$title-height: 45px;

.popup {
    width: 500px;
    top: 50%;
    left: 50%;
    max-height: 90%;
    min-height: 200px;
    overflow: hidden;
    position: fixed;
    z-index: 10;

    @media screen and (min-width: 1441px) {
        width: 500px;
    }
    
    @media screen and (max-width: 768px) {
        width: 80%;
    }
}

.large {
    width: 700px;
    
    @media screen and (min-width: 1441px) {
        width: 700px;
    }
    
    @media screen and (max-width: 768px) {
        width: 80%;
    }
}

.regular {
    width: 500px;
    
    @media screen and (min-width: 1441px) {
        width: 500px;
    }
    
    @media screen and (max-width: 768px) {
        width: 80%;
    }
}

.popup__external-wrapper {
    border-radius: 17px;
    padding: spacing(8);
    height: 100%;
    min-height: 150px;
    background-color: $basic_1;
}

.closeBtn {
    position: absolute;
    top: 18px;
    right: 18px;
}
.popup__content {
    flex-grow: 1;
    max-height: calc(100vh - 330px);
    overflow-y: auto;
}

.popup__footer {
    flex-direction: row;
    justify-content: center;
    align-items: center;

    > div {
        width: 100%;
    }

    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
    }
}

.popup__footer_alert {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    > div {
        width: 50%;
    }
}

.modal__backdrop {
    position: fixed;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    opacity: 0.5;
}

.popup__title {
    position: relative;
}

.popup__subtitle {
    position: relative;
}

.popup__message {
    line-height: 20px;
}

.buttons {
    margin-top: spacing(10);
}

// Animations
@mixin transform($val) {
    -webkit-transform: $val;
    transform: $val;
}

@mixin transition($transition...) {
    -webkit-transition: $transition;
    transition: $transition;
}

.slide-in-up {
    @include transform(scale(0.2) translateX(-100%) translateY(-50%));
    opacity: 0;
    @include transition(all cubic-bezier(.1, .7, .1, 1) 400ms);
}

.slide-in-up-active {
    @include transform(scale(1) translateX(-50%) translateY(-50%));
    opacity: 1;
}

.slide-in-up-leave {
    opacity: 0;
    @include transition(all ease-in-out 250ms);
}
