@import '../../../UI/sass/spacingUtils';

.list {
    display: block;
    list-style-type: decimal;
    margin-block-start: spacing(4);
    margin-block-end: spacing(4);
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: spacing(6);
}
