@import "../../sass/colors";

.label {
    font-size: 17px;
    line-height: 24px;
}

.labelWithEllipsis {
    max-width: 100%;
}

.isLink {
    text-decoration: underline;
    color: $primary;

    &:hover {
        cursor: pointer;
    }
}
