@import "../../../../UI/sass/colors";

.select {
    width: 300px;
}

.fullPrice {
    text-decoration: line-through;
    position: absolute;
    left: 40px;
}

.month {
    position: absolute;
    left: 195px;
    top: 5px;
}

.priceText {
    text-align: center;
}
