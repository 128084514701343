@import "../../../../UI/sass/spacingUtils";
@import "./columnVars";

.cell {
    padding: spacing(2) spacing(0);
    box-sizing: border-box;
}

.description {
    width: $description_col_width;
    flex-grow: 1;
    padding-right: spacing(1);
}

.quantity {
    width: $quantity_col_width;
    flex-shrink: 0;
}

.amount {
    width: $amount_col_width;
    justify-content: flex-end;
}