@import '../sass/colors';

.spinnerWrapper {
    position: relative;
    width: 100px;
    overflow: hidden;

    &:before {
        content: '';
        display: block;
        padding-top: 100%;
    }
}
.spinnerCircular {
    width: 100%;
    height: 100%;
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    fill: $primary;
    stroke: $primary;
}

.dmtSpinnerPath {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: square;
    -webkit-animation: dash 1.5s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite;
}

@-webkit-keyframes
rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes
rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes
dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}

@keyframes
dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}
