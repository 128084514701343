@import "../../sass/colors";
@import "../../sass/spacingUtils";

@mixin transform($val) {
  -webkit-transform: $val;
          transform: $val;
}

@mixin transform-origin($left, $top) {
  -webkit-transform-origin: $left $top;
          transform-origin: $left $top;
}

 @mixin transition-property($property) {
  -webkit-transition-property: $property;
          transition-property: $property;
}

@mixin transition-duration($transition-duration) {
  -webkit-transition-duration: $transition-duration;
          transition-duration: $transition-duration;
}

@mixin transition-timing-function($transition-timing) {
   -webkit-transition-timing-function: $transition-timing;
           transition-timing-function: $transition-timing;
 }

.wrapper {
    position: relative;
    background: transparent;
    width: 100%;
    margin: 0 auto;
}

@for $i from 1 to 6 {
    .width-#{$i}{
        width: calc(100%/6*$i - spacing(1));
    }
}
