@import "../../UI/sass/colors";
@import "../../UI/sass/spacingUtils";

.mainLayoutContainer {
    border-radius: 4px;
    border: 1px solid $border-color;

    flex-grow: 1;
    overflow: auto;

    margin-bottom: spacing(8);
}
