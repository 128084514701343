@import "../../../UI/sass/colors";
@import "../../../UI/sass/spacingUtils";

.box {
    border: 1px solid rgba($basic_0, $a_light-soft);
    border-radius: 4px;
    padding: spacing(3);
    margin-top: spacing(3);
}

.emptyBox {
    height: 80px;
}

.asterisks {
    vertical-align:sub;
}
