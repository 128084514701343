.feature {
    min-width: fit-content;
    width: 30%;
}

.name {
    text-decoration-style: dashed;
    text-decoration-line: underline;
    text-underline-offset: 8px;
}

.tooltip {
    max-width: 250px;
}
