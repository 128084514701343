@import "../../UI/sass/spacingUtils";

.content {
    width: 600px;
}

.explainBox {
    margin-left: -1 * spacing(9);
    width: 645px;
    margin-bottom: spacing(5);
}

.cancelSubscription {
    width: 600px;
}