@import '../../../UI/sass/spacingUtils';
@import '../../../UI/sass/colors';
@import "./sidemenu.variables";

.wrapper {
    background-color: mod_3($background);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: 1px solid $border-color;
    width: 260px;
    flex-shrink: 0;
    height: 100%;
    overflow-y: auto;
    justify-content: space-between;
    @media screen and (max-width: $collapsedMenuWidth) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;

        box-shadow: 1px -1px 8px 1px rgba(0,0,0,0.19);
        -webkit-box-shadow: 1px -1px 8px 1px rgba(0,0,0,0.19);
        -moz-box-shadow: 1px -1px 8px 1px rgba(0,0,0,0.19);
    }
}

.menuList {
    padding: 0 0 spacing(10);
    margin: 0 spacing(4);
}

.toggleBtn {
    position: absolute;
    right: 10px;
    top: 10px;
}
.copyrightWrapper {
    border-top: 1px solid rgba($basic_0, $a_low);
    padding: spacing(12) 0;
    margin: 0 spacing(8);
}

