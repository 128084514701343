@import "../sass/spacingUtils";
@import "../sass/colors";
@import "./mixins";

$spacing: (
        "p": ['padding'],
        "px": ['padding-left', 'padding-right'],
        "py": ['padding-top', 'padding-bottom'],
        "pt": ['padding-top'],
        "pr": ['padding-right'],
        "pb": ['padding-bottom'],
        "pl": ['padding-left'],
        "m": ['margin'],
        "mx": ['margin-left', 'margin-right'],
        "my": ['margin-top', 'margin-bottom'],
        "mt": ['margin-top'],
        "mr": ['margin-right'],
        "mb": ['margin-bottom'],
        "ml": ['margin-left'],
        "gap": ['gap'],
);
$sizes: (
        '0': 0px,
        'extra-extra-extra-small': 2px,
        '0_5': 2px,
        "extra-extra-small": spacing(1),
        "1": spacing(1),
        "extra-small": spacing(2),
        "2": spacing(2),
        "small": spacing(3),
        "3": spacing(3),
        "medium": spacing(4),
        "4": spacing(4),
        "large": spacing(5),
        "5": spacing(5),
        "extra-large": spacing(6),
        "6": spacing(6),
        "7": spacing(7),
        "8": spacing(8),
        "9": spacing(9),
        "10": spacing(10),
        "11": spacing(11),
        "12": spacing(12),
        "13": spacing(13),
        "14": spacing(14),
        "15": spacing(14),
        "none": spacing(0),
);

@each $attribute, $rules in $spacing {
        @each $name, $size in $sizes {
                .dmt-#{$attribute}-#{$name} {
                        @each $rule in $rules {
                                #{$rule}: $size;
                        }
                }
        }
}

.dmt-display-flex {
    display: -webkit-box !important;
    display: -moz-flex !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
}

.dmt-display-block {
    display: block !important;
}

.dmt-display-inline {
    display: inline !important;
}

.dmt-display-none {
    display: none !important;
}

.dmt-display-inline-flex {
    display: inline-flex !important;
}

.dmt-display-inline-block {
    display: inline-block !important;
}

.dmt-flex-direction-row {
    @include flex-direction(row);
}

.dmt-flex-direction-row-reverse {
    @include flex-direction(row-reverse);
}

.dmt-flex-direction-column {
    @include flex-direction(column);
}

.dmt-flex-direction-column-reverse {
    @include flex-direction(column-reverse);
}

.dmt-flex-nowrap {
    @include flex-wrap(nowrap);
}

.dmt-flex-wrap {
    @include flex-wrap(wrap);
}

.dmt-flex-wrap-reverse {
    @include flex-wrap(wrap-reverse);
}

.dmt-flex-shrink {
    @include flex-shrink(1);
}
.dmt-flex-noshrink {
    @include flex-shrink(0);
}

.dmt-flex-grow {
    @include flex-grow(1);
}
.dmt-flex-nogrow {
    @include flex-grow(0);
}

.dmt-flex-resize {
    @include flex(1, 1, auto);
}
.dmt-flex-noresize {
    @include flex(0, 0, auto);
}

.dmt-justify-content-flex-start {
    -ms-justify-content: flex-start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
}

.dmt-justify-content-flex-end {
    -ms-justify-content: flex-end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

.dmt-justify-content-center {
    -ms-justify-content: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.dmt-justify-content-space-between {
    -ms-justify-content: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

.dmt-justify-content-space-around {
    -ms-justify-content: space-around;
    -webkit-justify-content: space-around;
    justify-content: space-around;
}

.dmt-align-content-flex-start {
    -ms-align-content: flex-start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
}

.dmt-align-content-flex-end {
    -ms-align-content: flex-end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
}

.dmt-align-content-center {
    -ms-align-content: center;
    -webkit-align-content: center;
    align-content: center;
}

.dmt-align-items-flex-start {
    -ms-align-items: flex-start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
}

.dmt-align-items-flex-end {
    -ms-align-items: flex-end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
}

.dmt-align-items-center {
    -ms-align-items: center;
    -webkit-align-items: center;
    align-items: center;
}


.dmt-font-size-big {
    font-size: 20px;
    line-height: 24px;
}

.dmt-font-size-huge {
    font-size: 40px;
    line-height: 40px;
}

.dmt-font-size-normal {
    font-size: 16px;
    line-height: 20px;
}

.dmt-font-size-light {
    font-size: 14px;
    line-height: 18px;
}

.dmt-font-size-small {
    font-size: 12px;
    line-height: 16px;
}

.dmt-uppercase {
    text-transform: uppercase;
}
.dmt-font-bold {
    font-weight: 700;
}
.dmt-font-semibold {
    font-weight: 600;
}
.dmt-font-extrabold {
    font-weight: 800;
}

.dmt-font-italic {
    font-style: italic;
}
.dmt-font-light {
    font-weight: 300;
}
.dmt-no-select {
    span {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}

.dmt-is-link {
    color: $primary;
    &:hover {
        cursor: pointer;
    }
}

.dmt-text-ellipsis {
    display: block;
    @include ellipsis-text();
}

.dmt-full-width {
    width: 100%;
}

.dmt-full-height {
    height: 100%;
}
