@import "../../../../UI/sass/colors";

.closeButton {
    border-radius: 50%;
    padding: 2px;
    height: fit-content;
    width: fit-content;
}

.closeButton:hover {
    background-color: rgba($basic_0, $a_light-soft);
}

.closeButton:active {
    background-color: rgba($basic_0, $a_light);
}

.validationResultLabel {
    min-height: 18px;
}