@import "../../sass/colors";
@import "../../sass/spacingUtils";

@mixin placeholder-text($font-size) {
    &::placeholder {
        font-size: $font-size;
    }

    &::-moz-placeholder { // Firefox 19+
        font-size: $font-size;
    }
    &:-ms-input-placeholder { /* Firefox 18- */
        font-size: $font-size;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        font-size: $font-size;
    }
    :-ms-input-placeholder { /* IE 10+ */
        font-size: $font-size;
    }
}

.container {
    position: relative;
    margin: 1px;
}

$inputHeight: 35px;

.resetBtn {
    position: absolute;
    top: 0;
    right: 13px;
    bottom: 0;
    margin: auto 0;
}

$padding: spacing(2);

.input {
    color: rgba($basic_0, $a_hard);
    &.disabled {
        color: rgba($basic_0, $a_medium-soft);
        background: rgba($basic_0, $a_low);
        box-shadow: 0 0 0 1px rgba($basic_0, $a_low);
    }
    padding: 0 $padding;
    height: $inputHeight;

    position: relative;
    background: mod_3($basic_1);

    -webkit-transition: background-color 300ms linear;
    -moz-transition: background-color 300ms linear;
    -o-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    transition: background-color 300ms linear;

    width: calc( 100% - #{2*$padding} );
    box-shadow: 0 0 0 1px rgba($basic_0, $a_light-soft); /* emulate the border */
    border-radius: 4px;

    outline: none;
    border: none;

    &.error {
        box-shadow: 0 0 0 1px $danger; /* emulate the border */
    }

    &:hover:not(.disabled) {
        background-color: $basic_1;
        border-color: transparent; /* remove the border's colour */
        box-shadow: 0 0 0 1px rgba($primary, $a_medium-soft); /* emulate the border */
        cursor: pointer;

        &.error {
            box-shadow: 0 0 0 1px $danger; /* emulate the border */
        }
    }

    &:focus {
        background-color: $basic_1;
        border-color: transparent; /* remove the border's colour */
        box-shadow: 0 0 0 1px $primary; /* emulate the border */
        border-radius: 4px;
        &.error {
            box-shadow: 0 0 0 1px $danger; /* emulate the border */
        }
    }

    @include placeholder-text(14px);

    &::placeholder{
        color: rgba($basic_0, $a_medium);
    }

}
