.container {
    padding: 28px 0;
    height: 38px;
}

.logoWrapper {
    flex-grow: 1;
    img {
        width: 253px;
    }
}
