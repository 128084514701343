@import "../sass/colors";
@import "../sass/spacingUtils";

$button-colors: (
 "primary": $primary,
 "danger": $danger,
 "success": $success,
 "warning": $warning,
 "neutral": rgba($basic_0, $a_medium),
 "neutral-white": rgba($basic_1, $a_medium)
);

.base {
    border-radius: 4px;
    border: none;

    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    width: fit-content;

    height: 24px;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;

    padding: 3px spacing(3);
}
@each $color-name, $color in $button-colors {
    .color-#{$color-name} {
        color: $color;
        fill: $color;
        background-color: rgba($color, $a_light-soft);
    }
}
