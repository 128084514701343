@import "../../../UI/sass/colors";

.table {
    overflow: auto;
}

.row {
    height: 66px;
    align-items: center;
}
.openRow {
    background-color: rgba($warning, $a_low);
}
