@import '../../sass/colors';

.clickArea {
    position: relative;
    padding: 10px 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    input {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;

        &:hover {
            cursor: pointer;

            + .box:not(.checkboxDisabled) {
                border-color: rgba($primary, $a_medium-soft);
            }

            +.checkboxBoxChecked:not(.checkboxDisabled), +.checkboxBoxIndeterminate:not(.checkboxDisabled){
                background-color: mod_1($primary);
                border-color: mod_1($primary);
            }
        }

        &:active {
            cursor: pointer;

            + .box:not(.checkboxDisabled) {
                border-color: $primary;
            }

            +.checkboxBoxChecked:not(.checkboxDisabled), +.checkboxBoxIndeterminate:not(.checkboxDisabled){
                background-color: $primary;
                border-color: $primary;
            }
        }
    }
}

.box {
    border-radius: 2px;
    border-width: 1px;
    border-style: solid;
    margin-right: 16px;

    min-width: 18px;
    width: 18px;
    min-height: 18px;
    height: 18px;
    border-radius: 50%;

    background-color: mod_3($basic_1);
}

.checkboxBoxNotChecked {
    border-color: rgba($basic_0, $a_light);
}

.checkboxBoxChecked, .checkboxBoxIndeterminate{
    background-color: $primary;
    border-color: $primary;

    &:hover {
        background-color: mod_1($primary);
        border-color: mod_1($primary);
    }

    &.checkboxDisabled{
        background-color: mod_3($basic_0);
        border-color: rgba($basic_0, $a_medium);
    }
}

.checkboxBoxDisabled {
    .box  {
        background-color: rgba($basic_0, $a_low);
        border-color: transparent;
    }
    input {
        &:hover {
            cursor: auto;
        }
        &:checked {
            + .box {
                background-color: rgba($primary, $a_light);
            }
        }
    }
}
