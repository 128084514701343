@import "../../../sass/colors";
@import "../../../sass/spacingUtils";
.menuItem {
    &:hover {
        background-color: rgba($primary, $a_low);
    }
    width: 100%;
    position: relative;
}

.checked {
    background-color: mod_3($basic_1);

    &:hover {
        background-color: rgba($primary, $a_low);
    }
}

.menuItemInner {
    padding-left: spacing(4);
    padding-right: spacing(4);
    width: calc( 100% - #{spacing(8)} );
}

.checkIcon {
    align-self: flex-end;
}
