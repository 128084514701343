@use "sass:meta";
@import '../UI/sass/colors';

$colors: (
    "basic_0": $basic_0,
    "basic_1": $basic_1,
    "primary": $primary,
    "danger": $danger,
    "success": $success,
    "warning": $warning,
    "background": $background,
    "shadow": $shadow,
    "border-color": $border-color,
);

$modifiers: (
    "mod_1": mod_1,
    "mod_2": mod_2,
    "mod_3": mod_3,
    "mod_4": mod_4,
);

$alphas: (
    "a_hard": $a_hard,
    "a_hard-soft": $a_hard-soft,
    "a_medium": $a_medium,
    "a_medium-soft": $a_medium-soft,
    "a_light": $a_light,
    "a_light-soft": $a_light-soft,
    "a_low": $a_low,
);


@each $color-name, $color in $colors {
    .#{$color-name}{
        color: $color;
        fill: $color;
    }

    @each $alpha_name, $alpha in $alphas {
        .#{$color-name}-#{$alpha_name} {
            color: rgba($color, $alpha);
            fill: rgba($color, $alpha);
        }
    }

    @each $modifier-name, $modifier in $modifiers {
        $modified_color: meta.call(meta.get-function($modifier), $color);
        .#{$color-name}-#{$modifier-name} {
            color: $modified_color;
            fill: $modified_color;
        }

        @each $alpha_name, $alpha in $alphas {
            .#{$color-name}-#{$modifier-name}-#{$alpha_name} {
                color: rgba($modified_color, $alpha);
                fill: rgba($modified_color, $alpha);
            }
        }
    }
}
