@import "../UI/sass/colors";
@import "./reset";
@import "./colorClasses";

html {
  font-family: "Open Sans",Roboto,Helvetica Neue,Arial,sans-serif;
}

body {
  min-width: 800px;
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background;
}

.appContainer {
  min-height: 100vh;
  width: 1440px;
  max-width: 96%;
  height: 0;
}

a {
  text-decoration: none;
}

/* Scrollbar settings*/
html {
    --thumbBG: #d3dbe1;
}
*::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}
* {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) transparent;
}
*::-webkit-scrollbar-track {
    background: transparent;
}
*::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 2px;
}
