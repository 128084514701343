.container {
    margin: 0 auto;
    position: relative;
}

.maxWidthSm {
    max-width: 400px;
}
.maxWidthMd {
    max-width: 700px;
}
.maxWidthLg {
    max-width: 1200px;
}
