@import "../../../UI/sass/colors";

.pageContainer {
    background-color: $basic_1;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    width: 0;
    flex-grow: 1;
    scroll: auto;
}

.errorBox {
    height: 100%;
}
