@import "../sass/colors";
@import "../sass/spacingUtils";

$colors: (
    "primary": $primary,
    "danger": $danger,
    "success": $success,
    "warning": $warning
);

$margin: spacing(2);

.banner {
    border-radius: 4px;
    margin: $margin auto;
    padding: $margin;
    width: calc( 100% - $margin*3  )
}

.hidden {
    visibility: hidden;
}
@each $color-name, $color in $colors {
    .#{$color-name}{
        background-color: rgba($color, $a_light);
        border: 1px solid rgba($color, $a_medium);
    }
}
