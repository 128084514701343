@import "../../../UI/sass/spacingUtils";
@import "../../../UI/sass/colors";

.innerBox {
    height: 138px;
    justify-content: center;
    padding: 0 spacing(8);
    border: 1px solid $border-color;
}

.innerBox:first-child {
    flex-shrink: 0;
    border-right: 0;
    border-radius: 4px 0 0 4px;
}

.innerBox:last-child {
    border-left: 0;
    border-radius: 0 4px 4px 0;
    width: 100px;
    flex-basis: 100px;
    flex-shrink: 0;
    flex-grow: 1;
}

.row {
    gap: spacing(4);
    @media screen and (max-width: 930px){
        flex-direction: column;
    }
}
