.container {
    width: 100%;
    margin: 1px;
}

.selectButton {
    padding-top: 0;
    padding-bottom: 0;
    height: 35px;
}
