@import '../../sass/spacingUtils';
@import '../../sass/colors';

$button-colors: (
 "primary": $primary,
 "danger": $danger,
 "success": $success,
 "warning": $warning,
 "neutral": rgba($basic_0, $a_medium),
 "neutral-white": rgba($basic_1, $a_medium)
);

.button {
    border-radius: 4px;
    border: none;

    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    width: fit-content;

    &.iconOnly {
        padding: 0;
    }
}

.size-large {
    height: 40px;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;

    padding: 10px spacing(6);

    &.iconOnly {
        width: 40px;
        height: 40px;
    }
}

.size-medium {
    height: 32px;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;

    padding: 6px spacing(4);

    &.iconOnly {
        width: 32px;
        height: 32px;
    }
}

.size-small {
    height: 24px;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    
    padding: 3px spacing(3);

    &.iconOnly {
        width: 24px;
        height: 24px;
    }
}

.variant-primary {
    color: $basic_1;
    fill: $basic_1;

    :global(.spinner){
        stroke: $basic_1;
    }

    @each $color-name, $color in $button-colors {
        &.color-#{$color-name} {
            background-color: $color;

            &:hover {
                background-color: mod_1($color);
            }

            &:active {
                background-color: $color;
            }

            &.disabled {
                cursor: auto;
                background-color: rgba($color, $a_medium-soft);
            }
        }
    }
}


.variant-secondary {
    color: rgba($basic_0, $a_hard);
    fill: rgba($basic_0, $a_hard);
    :global(.spinner) {
        stroke: rgba($basic_0, $a_hard);
    }

    background-color: $basic_1;
    border-width: 1px;
    border-style: solid;
    border-color: rgba($basic_0, $a_light-soft);

    box-shadow: 0px 1px 2px $shadow;

    &:hover {
        background-color: mod_1($basic_1);
    }

    &:active {
        background-color: mod_2($basic_1);
    }

    &.disabled {
        cursor: auto;
        background-color: rgba($basic_1, $a_medium);
        border-color: rgba($basic_0, $a_low);
        color: rgba($basic_0, $a_light);
        fill: rgba($basic_0, $a_light);
        box-shadow: none;
    }
}

.variant-tertiary {
    color: $primary;

    svg {
        fill: $primary;
    }

    background-color: transparent;

    @each $color-name, $color in $button-colors {
        &.color-#{$color-name} {
            background-color: rgba($color, $a_light-soft);

            &:hover {
                background-color: rgba($color, $a_light);
            }

            &:active {
                background-color: rgba($color, $a_light-soft);
            }

            &.disabled {
                background-color: rgba($color, $a_light-soft);
                cursor: auto;
                color: rgba($color, $a_medium);
                fill: rgba($color, $a_medium);
            }
        }
    }
}

.variant-text {
    background-color: transparent;

    @each $color-name, $color in $button-colors {
        &.color-#{$color-name} {
            color: $color;
            fill: $color;

            &:hover {
                background-color: rgba($color, $a_light);
            }

            &:active {
                background-color: rgba($color, $a_light-soft);
            }

            &.disabled {
                cursor: auto;
                background-color: transparent;
                color: rgba($color, $a_medium);
                fill: rgba($color, $a_medium);
            }
        }
    }
}


.content {
    position: relative;
}

.loadingBox {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 20px;
}
