@import "../../sass/colors";
@import "../../sass/spacingUtils";

.menu {
    border-radius: 4px;
    border: 1px solid $border-color;

    box-shadow: 0 1px 12px 3px $shadow;
    padding: spacing(1) 0;
    overflow-y: auto;

    max-height: 400px;
}
