@import "../../UI/sass/colors";

.wrapper {
    border: 1px solid $border-color;
    border-radius: 4px;
    height: 360px;
}

.placeholder {
    max-width: 470px;
    text-align: center;
}