@use "sass:color";

/**
 IF YOU MODIFY THIS FILE, PLEASE MODIFY ALSO scss/_colorClasses.scss ACCORDINGLY
 */

/* Alpha */
$a_hard: 0.87;
$a_hard-soft: 0.64;
$a_medium: 0.48;
$a_medium-soft: 0.33;
$a_light: 0.21;
$a_light-soft: 0.12;
$a_low: 0.06;

/* Modifiers */

/* Colors */
$basic_0: #000000;
$basic_1: #ffffff;

$primary: #3E68FF;
$danger: #D8320D;
$success: #21BC07;
$warning: #FFC75A;

$background: #F2F4F7;
$profile-picture-colors: (
        0: #F46B62,
        1: #FBA575,
        2: #FFC804,
        3: #85D1AD,
        4: #71CAF0,
        5: #889EEA,
        6: #AC98E7,
        7: #C98BBF,
        gray: rgba($basic_0, $a_medium),
);

$shadow: rgba(0, 0, 0, 0.04);
$border-color: rgba($basic_0, $a_light-soft);

@function mod_1($base_color){
    @return color.adjust($base_color, $lightness: -5);
}

@function mod_2($base_color){
    @return color.adjust($base_color, $lightness: -7);
}

@function mod_3($base_color){
    @return color.change($base_color, $lightness: 98%);
}

@function mod_4($base_color){
    @return color.change($base_color, $lightness: 75%);
}

/**
 IF YOU MODIFY THIS FILE, PLEASE MODIFY ALSO scss/_colorClasses.scss ACCORDINGLY
 */
