.svgItem {
    flex-shrink: 0;
}

$sizes: (
    size-1: 10px,
    size-2: 16px,
    size-3: 20px,
    size-4: 24px,
    size-5: 32px,
    size-6: 48px,
    size-7: 64px,
);

@each $key, $value in $sizes {
.#{$key} {
    width: $value;
    height: $value;
}
}
