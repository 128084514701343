@import '../../../../UI/sass/colors';
@import '../../../../UI/sass/spacingUtils';

.item {
    border-radius: 3px;

    margin: 0 0 spacing(2);
    font-weight: 600;
    white-space: nowrap;
    justify-content: flex-start;
    cursor: pointer;

    &:hover {
        background-color: rgba($basic_0, $a_light-soft);
    }
}

.selected {
    background-color: $primary;
    &:hover {
        background-color: mod_1($primary);
    }
}
