@import "../../../UI/sass/colors";
@import "../../../UI/sass/spacingUtils";

.box {
    height: 138px;
    flex-basis: 125px;
    justify-content: center;
    padding: 0 spacing(8);
    border: 1px solid $border-color;
    border-radius: 4px;
    flex-shrink: 0;
    position: relative;
}

.infoIcon {
    position: absolute;
    top: 10px;
    right: 10px;
}
