@import "../../../UI/sass/colors";
@import "../../../UI/sass/spacingUtils";

$margin: spacing(6);
.box {
    height: 90px;
    width: calc( 50% - #{$margin/2});
    border-radius: 10px;
}

.planBox {
    background-color: rgba($success, $a_low);
    margin-right: $margin;
}

.legacyBox  {
    background-color: mod_3($basic_0);
}
