@import "../../../../../UI/sass/colors";
@import "../../../../../UI/sass/spacingUtils";

$licences_min_width: 220px;
$plan_detail_min_width: 310px;
$rightBox_min_width: 100px;

.planDetail {
    width: 33.33%;
    flex-basis: $plan_detail_min_width;
    flex-shrink: 0;
}

.rightBox {
    width: 33.33%;
    flex-grow: 1;
    flex-basis: $rightBox_min_width;
    flex-shrink: 0;
}

.upgradeBtn {
    margin-right: spacing(8);
}

$ribbon_height: 24px;
.ribbon {
    background-color: rgba($success, $a_medium-soft);
    height: $ribbon_height;
    padding-left: spacing(6);
    padding-right: spacing(4);
    position: relative;

    &:after {
    content: '';
    position: absolute;
    left: 0; bottom: 0; width: 0; height: 0;
    border-left: 5px solid $basic_1;
    border-top: $ribbon_height/2 solid transparent;
    border-bottom: $ribbon_height/2 solid transparent;
  }

}

.endpointCounter {
    width: 25%;
    margin-right: spacing(10);
    flex-basis: $licences_min_width;
    flex-shrink: 0;
    flex-grow: 2;
}

.discount {
    width: fit-content;
    width: -moz-fit-content;
    height: 24px;
    margin-left: 20px;
    border-radius: 4px;
    padding-right: spacing(2);
    padding-left: spacing(2);
    background-color: rgba($success, $a_light-soft);
}

.plan {
    height: 125px;
    padding: 0 0 0 spacing(10);
    border-bottom: 1px solid rgba($basic_0, $a_low);
    gap: spacing(6);

    &:last-child {
        border-bottom: none;
    }
}
