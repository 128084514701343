@import "../../sass/colors";
@import "../../sass/spacingUtils";

.thead {
  border-bottom: 1px solid rgba($basic_0, $a_low);
  border-top: 1px solid rgba($basic_0, $a_low);

  height: 64px;
  min-height: 64px;
}

.th {
  position: relative;
  height: 100%;
  border-right: 1px solid rgba($basic_0, $a_low);

  &.noBorder {
    border-right: none;
  }

  &:last-child {
    border-right: none;
  }
}

.sortable {
  select: none;
  cursor: pointer;
}
