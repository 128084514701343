@import "../../columnVars";
@import "../../../../../../UI/sass/colors";
@import "../../../../../../UI/sass/spacingUtils";

.label {
    width: 100% - $amount_col_width;
}

.amount {
    width: $amount_col_width * 2;
    justify-content: flex-end;
}

.row {
    padding: spacing(2) spacing(0);
    border-top: 1px solid rgba($basic_0, $a_light);
}