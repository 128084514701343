@import "../sass/colors";

.profilePicture {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    flex-shrink: 0;
}

@each $index, $color in $profile-picture-colors {
    .background-#{$index} {
        background-color: $color;
    }
}

