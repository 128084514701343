@import "../../../../UI/sass/colors";
@import "../../../../UI/sass/spacingUtils";

.container {
    border-bottom: 1px solid rgba($basic_0, $a_low);

    &:last-child {
        border-bottom: none;
    }
}

.category {
    background-color: mod_3($basic_1);
    flex-basis: 96px;
    padding: spacing(8) spacing(10);
    flex-shrink: 0;
}

.plans {
    margin: 0 0 0 spacing(10);
}


