@import "../../../../../UI/sass/spacingUtils";
.cell {
    height: 34px;
    width: calc( 100% - #{spacing(6) * 2} );
    padding-left: spacing(6);
    padding-right: spacing(6);
}

.texts {
    width: 0;
}
