@import "../../../../UI/sass/colors";
@import "../../../../UI/sass/spacingUtils";

.spacer {
    width: 60%;
}

.bottom-border-separator {
    border-bottom: 1px solid rgba($basic_0, $a_light);
}

.hidden {
    display: none !important;
}
