@import "../../sass/colors";

.tr {
  display: flex;
  width: fit-content;
  height: 64px;
  min-width: 100%;
  align-items: center;

  &:hover {
    background-color: rgba($primary, $a_low);
  }

  border-bottom: 1px solid rgba($basic_0, $a_low);
}
